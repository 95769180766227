import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import { AttributesHirachy } from '../EditListingPage/EditListingWizard/EditListingAttributesPanel/AttributesHirachy';
import Case from '../../assets/Case.png'
import AttributesIcon from '../../assets/Stroke.png';
import Gear from '../../assets/WheelGear.png';

import css from './ListingPage.module.css'

function SectionAttributesMaybe(props) {
    const { publicData } = props

    const checkedAttributes = Object.entries(publicData.attributes).filter(([key, value]) => value !== "" && value !== true);

    // const renderedAttributes = checkedAttributes
    //     .sort(([keyA, valueA], [keyB, valueB]) => {
    //         const matchingAttributeA = AttributesHirachy.find(a => a.key === keyA) || {};
    //         const matchingAttributeB = AttributesHirachy.find(a => a.key === keyB) || {};
    //         return matchingAttributeA.level - matchingAttributeB.level;
    //     })
    //     .map(([key, value]) => {
    //         const matchingAttribute = AttributesHirachy.find(a => a.key === key) || {};
    //         const { label, icon } = matchingAttribute;

    //         return (
    //             <div key={key} className={css.servicesWrapper}>
    //                 <div className={css.services}>{icon && <img src={icon} />}
    //                     <strong>{label}:</strong>{' '}
    //                     {Array.isArray(value)
    //                         ? value.map((v, i) => {
    //                             const matchedItem = AttributesHirachy.find(a => a.key === v) || {};
    //                             return (
    //                                 matchedItem.label + `${i + 1 < value.length ? ', ' : ''}`
    //                             );
    //                         })
    //                         : AttributesHirachy.find(a => a.key === value.split('-')[1]).label}
    //                 </div>
    //             </div>
    //         );
    //     });

    const flattenArray = (arr) => {
        return arr.reduce((acc, item) => {
            return Array.isArray(item) ? [...acc, ...flattenArray(item)] : [...acc, item];
        }, []);
    };

    const groupByLevel = (array) => {
        return array.reduce((result, item) => {
            const { level } = item;
            if (level !== 1) {
                if (!result[level]) {
                    result[level] = [];
                }
                result[level].push(item.label);
            }
            return result;
        }, {});
    };

    const groupedAttributes = groupByLevel([...new Set(flattenArray(checkedAttributes))].map((key) => {
        const validKey = key.includes('-') ? key.split('-')[1] : key;
        const matchingAttribute = AttributesHirachy.find(a => a.key === validKey) || {};
        const { label, level } = matchingAttribute;

        return { label, level };

    }));

    const levelsWithIcons = {
        "2": {
            label: 'Art',
            icon: Case,
        },
        "3": {
            label: 'Diese Dienste sind verfügbar in',
            icon: AttributesIcon,
        },
        "4": {
            label: 'Benötigte Ausrüstung',
            icon: Gear,
        }
    }

    return (
        <>
            <div className={css.SectionBikeTypes}>
                <div>{Object.keys(levelsWithIcons).map(num => {
                    const data = groupedAttributes[Number(num)];
                    if (data && data.length > 0) {
                        return <div className={css.services}>
                            <img src={levelsWithIcons[num].icon} />
                            <div>
                               <div className={css.servicesLabel}>
                               {levelsWithIcons[num].label}
                               </div> 
                                {data.join(', ')}
                            </div>
                        </div>
                    };
                })}</div>
            </div>
            <hr />
        </>
    )
}

export default SectionAttributesMaybe