import React from 'react'
import { bikeTypeOptions } from '../../util/listingShared';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import Ticks from '../../assets/ticks.png'
import Bike from '../../assets/bigBike.png'
import css from './ListingPage.module.css'

function SectionBikeTypesMaybe(props) {
    const { publicData, suitabilityRef } = props;
    const bikeType = publicData.bikeTypes.map(bikeTypeKey => {
        return <>
            <div className={css.typesWrapper}>
                <div className={css.bikeTypeWrapper}>
                    <img src={Ticks} />
                    <div className={css.bikeTypeText}>{bikeTypeOptions.find(b => b.key == bikeTypeKey)?.label}</div>
                </div>
            </div>
        </>
    });

    return (
        <>
            <div className={css.SectionBikeTypes} ref={suitabilityRef}>
                <div className={css.typesHeader}>
                    <img src={Bike} />
                    <Heading as="h2" rootClassName={css.sectionHeading}>
                        <FormattedMessage id="ListingPage.bikeTypeTitle" />
                    </Heading>
                </div>
                <div>{bikeType}</div>
            </div>
            <hr />
        </>
    )
}

export default SectionBikeTypesMaybe