import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import css from './ListingPage.module.css'
import Mark from '../../assets/Mark.png'

function SectionAdditionalInformatioMaybe(props) {
    const { publicData } = props
    return (
        <>
            {publicData.additionalDescription ? <> <div className={css.SectionBikeTypes}>
                <div className={css.infoHeader}>
                    <img src={Mark} />
                    <Heading as="h2" rootClassName={css.sectionHeading}>
                        <FormattedMessage id="ListingPage.additionalInformationTitle" />
                    </Heading>
                </div>
                <div className={css.additionalInfoWrapper}>
                    <div className={css.additionalInfoText}>
                        {publicData.additionalDescription}
                    </div>
                </div>
            </div>  <hr /></> : null
            }
        </>
    )
}

export default SectionAdditionalInformatioMaybe