import React from 'react';
import Pin from "../../assets/pin.png";
import Bike from "../../assets/bike.png";
import starIcon from "../../assets/icons/star.png";
import { bikeTypeOptions } from '../../util/listingShared';
import css from './ListingPage.module.css';

function SectionElementMaybe(props) {
    const { publicData, mapRef, suitabilityRef, reviewsRef } = props;

    const listingLocation = publicData?.location?.address;
    const reviews = publicData?.reviewScore / 10;

    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
    const mobileMaxLimit = 14;
    const desktopMaxLimit = 30;

    const truncateText = (text, maxLimit) => {
        if (text.length > maxLimit) {
            return text.slice(0, maxLimit) + '...';
        };
        return text;
    };

    const bikeType = publicData.bikeTypes.map(bikeTypeKey => {
        return bikeTypeOptions.find(b => b.key == bikeTypeKey)?.label;
    });

    let types = bikeType.join(', ');
    types = truncateText(types, isMobile ? mobileMaxLimit : desktopMaxLimit);

    let listingAddress = listingLocation;
    listingAddress = truncateText(listingAddress, isMobile ? mobileMaxLimit : desktopMaxLimit);

    const showReviewScore = reviews && reviews !== 0;

    return <div className={css.sectionElements}>
        <div className={css.elementWrapper}>
            <div className={css.locationWrapper}>
                <img src={Pin} />
                <p onClick={() => mapRef.current.scrollIntoView({ behavior: "smooth" })}>
                    {listingAddress}
                </p>
                <span className={css.bulletPoint}>•</span>
            </div>

            <div className={css.locationWrapper}>
                <img src={Bike} />
                <p onClick={() => suitabilityRef.current.scrollIntoView({ behavior: "smooth" })}>
                    {types}
                </p>
                {showReviewScore ? <span className={css.bulletPoint}>•</span> : null}
            </div>

            {showReviewScore ? <div className={css.locationWrapper}>
                <img src={starIcon} />
                <p onClick={() => reviewsRef.current.scrollIntoView({ behavior: "smooth" })}>
                    {reviews ? reviews : 0}/5
                </p>
            </div> : null}
        </div>
    </div>
};

export default SectionElementMaybe;
