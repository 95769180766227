import AttributesIcon from '../../../../assets/attributes.png';
import Level from '../../../../assets/attributes/level.png';
import Wrench from '../../../../assets/attributes/wrench.png';
import Suitcase from '../../../../assets/attributes/suitcase.png';
import Faces from '../../../../assets/attributes/faces.png';

export const AttributesHirachy = [
    // leistungsdiagnostik
    {
        "key": "performanceDiagnostics",
        "label": "Leistungsdiagnostik",
        "level": 1,
        "icon": AttributesIcon,
        "parent": "leistungsdiagnostik"
    },
    {
        "key": "fieldTest",
        "label": "Feldtest",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "laboratory",
        "label": "Labortest",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "spiroErgometry",
        "label": "Spiroergonometrie",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "vo2MaxTest",
        "label": "Vo2Max Test",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "lactateProfile",
        "label": "Laktat-Stufen-Tets",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "ownBike",
        "label": "Eigenes Fahrrad",
        "level": 2,
        "parent": "performanceDiagnostics",
    },
    {
        "key": "ergometer",
        "label": "Ergometer",
        "level": 2,
        "parent": "performanceDiagnostics",
    },

    // Bike fitting
    {
        "key": "bike",
        "label": "Bikefitting",
        "level": 1,
        "icon": Wrench,
        "parent": "bikefitting"
    },
    {
        "key": "sattelDruckAnalyse",
        "label": "Sattel-Druck-Analyse",
        "level": 2,
        "parent": "bike",
    },
    {
        "key": "schuheinstellung",
        "label": "Schuheinstellung",
        "level": 2,
        "parent": "bike",
    },

    // coaching
    {
        "key": "coachingLevel",
        "label": "Level",
        "level": 1,
        "icon": Level,
        "parent": "coaching"
    },
    {
        "key": "anfanger",
        "label": "Anfänger",
        "level": 2,
        "parent": "coachingLevel",
    },
    {
        "key": "amateur",
        "label": "Amateur",
        "level": 2,
        "parent": "coachingLevel",
    },
    {
        "key": "professionell",
        "label": "Professionell",
        "level": 2,
        "parent": "coachingLevel",
    },

    // Courses
    {
        "key": "coursesLevel",
        "label": "Level",
        "level": 1,
        "icon": Level,
        "parent": "kurse"
    },
    {
        "key": "beginner",
        "label": "Einsteiger",
        "level": 2,
        "parent": "coursesLevel",
    },
    {
        "key": "advanced",
        "label": "Fortgeschritten",
        "level": 2,
        "parent": "coursesLevel",
    },
    {
        "key": "expert",
        "label": "Experte",
        "level": 2,
        "parent": "coursesLevel",
    },

    // Guides
    {
        "key": "guidesLevel",
        "label": "Ausrichtung",
        "level": 1,
        "icon": Suitcase,
        "parent": "guides"

    },
    {
        "key": "sightseeing",
        "label": "Sightseeing",
        "level": 2,
        "parent": "guidesLevel",
    },
    {
        "key": "sportlich",
        "label": "Sportlich",
        "level": 2,
        "parent": "guidesLevel",
    },

    // Aero Fitting
    {
        "key": "fittingLevel",
        "label": "Ort der Durchführung",
        "level": 1,
        "icon": AttributesIcon,
        "parent": "aerofitting"

    },
    {
        "key": "velodrom",
        "label": "Velodrom",
        "level": 2,
        "parent": "fittingLevel",
    },
    {
        "key": "feldtest",
        "label": "Feldtest",
        "level": 2,
        "parent": "fittingLevel",
    },

    //Events On/Off the bike
    {
        "key": "eventsLevel",
        "label": "Veranstaltungen",
        "icon": Faces,
        "level": 1,
        "parent": "events",
    },
    {
        "key": "granFondo",
        "label": "Gran Fondo",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "marathon",
        "label": "Marathon",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "radreise",
        "label": "Radreise",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "socialRide",
        "label": "Social ride",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "race",
        "label": "Rennen",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "officialRace",
        "label": "Jedermann-Rennen",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "workshop",
        "label": "Workshop/Seminar",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "vortrag",
        "label": "Vortrag",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "fair",
        "label": "Messe/Ausstellung",
        "level": 2,
        "parent": "eventsLevel",
    },
    {
        "key": "sonstiges",
        "label": "Sonstiges",
        "level": 2,
        "parent": "eventsLevel",
    },
];

const getParentObj = (key) => {
    return AttributesHirachy.find(obj => obj.key == key);
};

export const getKeyWithParents = (key) => {
    let foundObject = getParentObj(key);
    let fullKey = key;

    while (foundObject && foundObject.parent) {
        const parentKey = foundObject.parent;
        const parentObject = getParentObj(parentKey);

        if (parentObject) {
            fullKey = `${parentKey}_${fullKey}`;
            foundObject = parentObject;
        } else {
            fullKey = `${parentKey}_${fullKey}`;
            break;
        };
    };

    return fullKey;
};
